import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseTypographyProp, } from '@haaretz/htz-css-tools';
import { useRouter, } from 'next/router';

import Kicker from './Kicker';
import useAbTestGroup from '../../hooks/Page/useAbTestGroup';
import useIsBlock from '../../hooks/useIsBlock';

HeaderText.propTypes = {
  /** Class(es) to be added to the DOM element.
   * Can be passed by manually, or by Fela's createComponent
   */
  className: PropTypes.string,
  /**
   * Kicker of Article. A short, catchy word or phrase over a major headline
   * mobileExclusive will be used as the kicker text on mobile,
   * exclusive will be used as the kicker text in all other views
   */
  exclusive: PropTypes.string,
  mobileExclusive: PropTypes.string,
  /** Headline (title) of an Article */
  title: PropTypes.string.isRequired,
  /** Subtitle of an Article */
  subtitle: PropTypes.string,
  includeExclusiveInTitle: PropTypes.bool,
  isHighlight: PropTypes.bool,
};

HeaderText.defaultProps = {
  className: null,
  exclusive: null,
  mobileExclusive: null,
  subtitle: null,
  includeExclusiveInTitle: null,
  isHighlight: null,
};

const titleStyle = ({ theme, }) => ({
  display: 'inline',
  extend: [
    parseTypographyProp(theme.articleStyle.header.titleFontSize, theme.type),
  ],
});

const subTitleStyle = ({ theme, }) => ({
  fontWeight: 'normal',
  marginTop: theme.articleStyle.header.subTitleMarginTop,

  extend: [ theme.type(1),
  theme.recipeArticleStyle?.subTitleType ? parseTypographyProp(theme.timelineArticleStyle.subTitleType, theme.type) : {},
  ],
});

const hideOnMobile = ({ theme, }) => theme.mq({ until: 's', }, { display: 'none', });
const hideOnDesktop = ({ theme, }) => theme.mq({ from: 's', }, { display: 'none', });

function HeaderText({
  title,
  subtitle,
  exclusive,
  mobileExclusive,
  className,
  includeExclusiveInTitle,
  isHighlight,
}) {
  const { css, theme, } = useFela();
  const router = useRouter();
  const { abTestGroup, } = useAbTestGroup(router);
  const block = useIsBlock();

  const showArticleHeader = !block || abTestGroup !== 'B';

  const kicker = exclusive || mobileExclusive ? (
    <Kicker
      isBlock={false}
      fontSize={theme.articleStyle.header.kickerFontSize}
      displayOnDesktop={!!exclusive}
      displayOnMobile={!!mobileExclusive}
      miscStyles={{ fontWeight: '400', ...isHighlight ? { color: [ { until: 's', value: theme.color('negative', '-2'), }, ], } : {}, }}
    >
      {exclusive !== mobileExclusive ? (
        <React.Fragment>
          {exclusive && <span className={css(hideOnMobile)}>{exclusive}</span>}
          {mobileExclusive && <span className={css(hideOnDesktop)}>{mobileExclusive}</span>}
        </React.Fragment>
      ) : (
        exclusive
      )}
    </Kicker>
  ) : null;

  return (
    <div className={className} data-test="articleHeader">
      {!includeExclusiveInTitle && kicker}
      <h1 className={css(titleStyle)} data-test="articleHeaderTitle">
        {includeExclusiveInTitle ? (
          <React.Fragment>
            {kicker}
            {title}
          </React.Fragment>
        ) : (
          title
        )}
      </h1>
      {subtitle && showArticleHeader && !isHighlight ? <p className={css(subTitleStyle)} data-test="articleHeaderSubtitle">{subtitle}</p> : null}
    </div>
  );
}

export default HeaderText;
